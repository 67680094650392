import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const Home = () => {
  const innerWidth = window.innerWidth
  return (
    <Grid  className='home'>
      <Grid container sx={{ px: {lg:'76px', md: '24px', xs: '0' }, paddingTop: '50px' }} alignItems='center'>
        <Grid item xs={12} md={6} sx={{ px:{md:'48px',xs:'16px'} }} >
          <Grid>
            <Typography className='intro-title'>
              <Typography  fontSize={{lg:'3rem',md:'2rem',xs:'1.4rem'}} >Master the habit of</Typography>
              <Typography component='strong' fontSize={{md:'4rem',xs:'3rem'}}>Meditation</Typography>
            </Typography>
          </Grid>
          <Typography color='#0B0B0B' fontWeight='300' fontSize='1.3rem' className='intro-desc' mb={5} mt={4}>
            Heart-In-Tune helps you experience peace, the starting point of many good things in life. This app is brought to you by&nbsp;
            <a href='https://heartfulness.org/'>
              Heartfulness
            </a>
          </Typography>

          <Grid container className='img-wrap'>
            <Grid mr={2}>
              <a href="https://apps.apple.com/ph/app/heart-in-tune/id1576857513" target="_blank"  >
            <img src='assets/images/app-store-dwn.png' className="app-logo" />
              </a>
            </Grid>
            <Grid>
              <a href="https://play.google.com/store/apps/details?id=org.heartfulness.heartintune.prod" target="_blank" >
                <img src="/assets/images/play-store-dwn.png" className="app-logo" />

              </a>
            </Grid>


          </Grid>

        </Grid>
        <Grid item xs={12} md={6} lg={6}  sx={{ px:{md:'48px',xs:'16px'} }}>
          <Grid container flexDirection='column' >
            {innerWidth < 900 ?
              <>
                <Box sx={{ position: 'relative',width:'100%',height:'auto' }} >
                  <img src='/assets/images/banner-1-mb.png' width='100%' height='auto' />
                  {/* <Box sx={{position:'absolute',bottom:'0'}} className='audio' >
                  <audio controls>
                    <source src="../../../assets/audio/meditation.mp3" type="audio/*" />
                  </audio>
                </Box> */}
                </Box>
                
              </> :
              <>
                <Box sx={{ position: 'relative',width:'100%',height:'auto' }} >
                  <img src='/assets/images/Home-Banner-Image@2x.png' width='100%' height='auto' />
                  {/* <Box sx={{position:'absolute',bottom:'0', left:'50px' }} className='audio'>
                  <audio controls>
                    <source src="../../../assets/audio/meditation.mp3" type="audio/*" />
                  </audio>
                </Box> */}
                </Box>
               
              </>
            }

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
