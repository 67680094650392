import { AppBar, Grid, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Navbar = () => {
    return (
        <Box>
            <AppBar  position="static"
             sx={{ background: 'linear-gradient(180deg,rgba(242,244,255,0.8) 0%,rgba(255,242,242,0.8) 0.01%)',boxShadow:'none'}}  >
            <Toolbar sx={{ padding: { md: '16px 24px', xs: '16px 16px' } }} >
                <Grid container justifyContent="space-between" alignItems="center" sx={{ px: {lg:'100px', md: '50px', xs: '0' } }}>
                    <Box>
                        <a href='/'>
                            <img
                                className='logo'
                                src='assets/images/app-logo-new.png'
                                // height='auto'
                                // width='200px'
                            />
                        </a>
                    </Box>
                </Grid>
            </Toolbar>
        </AppBar>
      </Box >
  );
};

export default Navbar;
