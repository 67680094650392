import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home/home'
import Navbar from './component/navbar';
function App() {
  return (
    <>
    <Navbar/>
    <Home/>
    </>
    
  );
}

export default App;
